import { db } from "../firebase";

export const getAllOrganizations = async () => {
  const result = db
    .collection("organization")
    .orderBy("title", "asc")
    .get()
    .then((querySnapshot2) => {
      const orgs = [];
      querySnapshot2.forEach((doc) => {
        const organization = {
          id: doc.id,
          ...doc.data(),
        };
        orgs.push(organization);
      });
      return orgs;
    })
    .catch((error) => {
      console.error("Error getting organizations: ", error);
    });
  return result;
};

export const getOrganizationsByOwner = async () => {
  const userID = sessionStorage.getItem("ID");
  const result = db
    .collection("owners")
    .where("owner", "==", userID)
    .get()
    .then((querySnapshot) => {
      const myOrgs = [];
      querySnapshot.forEach((doc) => {
        const owners = {
          id: doc.id,
          ...doc.data(),
        };
        myOrgs.push(owners);
      });

      return db
        .collection("organization")
        .orderBy("title", "asc")
        .get()
        .then((querySnapshot2) => {
          const orgs = [];
          querySnapshot2.forEach((doc) => {
            const organization = {
              id: doc.id,
              ...doc.data(),
            };
            const orgIsFromOwner = myOrgs.filter(
              (x) => x.orgID === organization.id
            ).length
              ? true
              : false;
            if (orgIsFromOwner) {
              orgs.push(organization);
            }
          });
          return orgs;
        })
        .catch((error) => {
          console.error("Error getting organizations: ", error);
        });
    })
    .catch((error) => {
      console.error("Error getting owners: ", error);
    });
  return result;
};
