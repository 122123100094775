import React from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { SignIn, LogOut } from "../../authentication/firebaseutils";

const NavBar = () => {
  const user = sessionStorage.getItem("user");
  return (
    <header>
      <Navbar collapseOnSelect expand="xl" bg="info" variant="dark">
        <Container>
          <Navbar.Brand href="/home">Te Ka</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav>
              {user === null || user === "" ? (
                <Button onClick={SignIn} variant="light">
                  Log in
                </Button>
              ) : (
                <Button onClick={LogOut} variant="light">
                  Log out
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavBar;
