import React from "react";
import { Navbar, Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Navbar fixed="bottom">
        <Container
          fluid
          className="justify-content-center justify-content-sm-end"
        >
          <Row>
            <Col>Powered By Topic Base. &copy;</Col>
          </Row>
        </Container>
      </Navbar>
    </footer>
  );
};

export default Footer;
