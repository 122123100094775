import React, { useState, useEffect } from "react";
import CreateEditOrganization from "./CreateEditOrganization";
import { Card, Button } from "react-bootstrap";
import { getOrganizationsByOwner } from "../../services/organizations";

function ShowOrganization(props) {
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);

  const ShowModalFromEdit = (id) => {
    props.setTitleText("Save");
    props.setButtonText("Save");
    props.setId(id);
    props.handleShow();
  };

  // Runs on componentDidMount
  useEffect(() => {
    getOrganizationsByOwner().then((response) => {
      setOrgs([...response]);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   console.log(orgs);
  // }, [orgs]);

  return (
    <>
      {orgs.length ? (
        <>
          {orgs.map((data) => (
            <Card key={data.id} className="mt-3 mb-3" style={{ color: "#000" }}>
              <Card.Header as="h3" align="left">
                {data.title}
              </Card.Header>
              <Card.Body>
                <div align="left">{data.description}</div>
                <div align="right">
                  <Button
                    className="Button"
                    variant="info"
                    onClick={() => ShowModalFromEdit(data.id)}
                  >
                    Edit
                  </Button>
                  <hr />
                </div>
              </Card.Body>
            </Card>
          ))}
        </>
      ) : loading ? (
        <>
          <h2 className="text-center">Loading...</h2>
        </>
      ) : (
        <>
          <h2 className="text-center">No organizations yet.</h2>
        </>
      )}
      <>
        <CreateEditOrganization id={props.id} />
      </>
    </>
  );
}

export default ShowOrganization;
