import React, { useState } from "react";
import Modal from "./CreateEditOrganization";
import ShowOrganization from "./ShowOrganization";
import { db } from "../../firebase";
import { Container, Row, Col, Button } from "react-bootstrap";

function Organization() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [buttonText, setButtonText] = useState("Create");
  const [titleText, setTitleText] = useState("Create");
  const [id, setId] = useState("");
  const [orgExist, setOrgExist] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const handleClose = () => {
    setShow(false);
    setButtonText("Create");
    setTitleText("Create");
    setId("");
  };
  const handleShow = () => setShow(true);

  const GetOrganizations = async () => {
    const MyOrgs = []; // saves the ID's of the organization
    let flag = false; // to know of we can save or no the organization
    setShowSpinner(true);
    // Catch the ID's of the organization that belongs to the logged user
    db.collection("owners")
      .where("owner", "==", sessionStorage.getItem("ID"))
      .onSnapshot((Snapshot) => {
        if (Snapshot.empty) {
          setOrgExist(false);
        } else {
          Snapshot.forEach((doc) => {
            let orgInfo = doc.data();
            MyOrgs.push(orgInfo.orgID);
          });
        }

        // once we have all the ID's,We'll Catch the organization collection but just save the organization
        // that are in the MyOrgs array
        db.collection("organization")
          .where("archive", "==", false)
          .onSnapshot((Snapshot) => {
            if (Snapshot.empty) {
              setOrgExist(false);
            } else {
              const docInfo = []; // here will save the organization object
              Snapshot.forEach((doc) => {
                for (let i = 0; i < MyOrgs.length; i++) {
                  if (doc.id === MyOrgs[i]) {
                    flag = true;
                  }
                }
                if (flag === true) {
                  // if is true saves the info of the organization
                  docInfo.push({ ...doc.data(), id: doc.id });
                  flag = false;
                }
              });
              //function to OrderBy title alphabetically key insensitive
              docInfo.sort(function (a, b) {
                const titleA = a.title.toLowerCase();
                const titleB = b.title.toLowerCase();
                if (titleA > titleB) {
                  return 1;
                }
                if (titleA < titleB) {
                  return -1;
                }
                return 0;
              });

              if (docInfo.length === 0) {
                setOrgExist(false);
              } else {
                setOrgExist(true);
              }
              setData(docInfo); // save data on the Data state
              setShowSpinner(false);
            }
          });
      });
  };

  const user = sessionStorage.getItem("user");

  return (
    <>
      {user !== "" && user !== null ? (
        <Container>
          <Row className="mt-4 mb-4 d-flex align-items-sm-center">
            <Col className="d-flex justify-content-center">
              <h1>Organizations List</h1>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  handleShow();
                }}
              >
                New Organization
              </Button>
            </Col>
          </Row>
          <Row className="mt-4 mb-4">
            <Col>
              <ShowOrganization
                setId={setId}
                id={id}
                setTitleText={setTitleText}
                titleText={titleText}
                setButtonText={setButtonText}
                buttonText={buttonText}
                show={show}
                handleShow={handleShow}
                GetOrganizations={GetOrganizations}
                data={data}
                orgExist={orgExist}
                showSpinner={showSpinner}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Modal
                id={id}
                setTitleText={setTitleText}
                titleText={titleText}
                setButtonText={setButtonText}
                buttonText={buttonText}
                show={show}
                onHide={handleClose}
                handleClose={handleClose}
                href="#SignIn"
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row className="mt-5">
            <Col className="text-center">
              <h1>Please Log in to see organizations</h1>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Organization;
